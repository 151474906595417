// extracted by mini-css-extract-plugin
export var About = "style-module--About--WP-CE";
export var Description = "style-module--Description--yIJmk";
export var Hero = "style-module--Hero--Ek0h8";
export var HeroContainer = "style-module--HeroContainer--UXEFt";
export var Image = "style-module--Image--9Q92b";
export var ImagePrefix = "style-module--ImagePrefix--I0TyW";
export var ImageWrapper = "style-module--ImageWrapper--J-Jtt";
export var Intro = "style-module--Intro--1eHIE";
export var Subtitle = "style-module--Subtitle--TN+yZ";
export var Title = "style-module--Title--r9lyg";
export var heroImage = "style-module--heroImage--fc67O";
export var heroImageCont = "style-module--heroImageCont--3mYaT";